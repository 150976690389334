.footer-container{
    display: flex;
    flex-grow: 1;
    flex-direction: column;
}

.footer-links {
    font-size: 12px;
    font-weight: 600;
    padding-top: var(--spacing-2);
    padding-bottom: var(--spacing-1);
}

    .footer-links a {
        margin-left: var(--spacing-2);
        margin-right: var(--spacing-2);
        text-decoration: none;
        color: var(--primary-text);
    }

    .footer-links a:hover {
        color: var(--primary-text-hover);
    }

.footer-copyrights {
    color: var(--primary-text);
    font-size: 10px;
    opacity: 0.8;
    padding-bottom: var(--spacing-3);
}