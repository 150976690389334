.comments-primary{
    font-size: 14px;
    font-weight: 500;
    color: var(--primary-text);
}

.comments-secondary{
    font-size: 12px;
    opacity: 0.8;
    font-weight: 400;
    font-variant-caps: titling-caps;
    color: var(--primary-text);
}

.comments-secondary-clickable:hover{
    cursor: pointer;
    color: var(--primary-text-hover);
}

.comments-reference{
    font-style: italic;
}

.comments-reference-container:hover{
    cursor: pointer;
    opacity: 0.6;
}

.comments-clip{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.comments-border{
    background-color: var(--surface-background);
    border-radius: var(--spacing-3);
    border: solid 1px var(--control-stroke);
}

.comments-border-reference{
    background-color: var(--control-background);
}

